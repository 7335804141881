import { createTheme } from "@mui/material";

import { palette } from "./palette";
import { typography } from "./typography";
// import { spacing } from "./spacing";

export const baseTheme = createTheme({
  palette,
  typography,
  // spacing,
});
