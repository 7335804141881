import { Components } from "@mui/material";

export const MuiTypography: NonNullable<Components["MuiTypography"]> = {
  styleOverrides: {
    h1: {
      fontWeight: 500,
    },
    h2: {
      fontWeight: 500,
    },
    h3: {
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
  },
};
