import React from "react";

const NoMatch = () => {
  return (
    <div className="nomatch">
      <h1 className="heading1">Ugh, so sorry, no such page! 404 </h1>
    </div>
  );
};

export default NoMatch;
